import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { MetaMaskSDK } from "@metamask/sdk";
import { Alert, Button, Col, Container, Form, Modal,  OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Connection, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import { BINANCE_W3, SOLANA_ENDPOINT, TETHER_WALLET, TETHER_WALLET_CONTRACT } from '../Util/constant';
import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faCopy } from '@fortawesome/free-solid-svg-icons';

import solflareImg from '../assets/images/solflare-white.svg';
import phantomImg from '../assets/images/phantom.svg';
import metamaskImg from '../assets/images/metamask-logo.svg';
import coinbaseImg from '../assets/images/coinbase.svg';

import Solflare from '@solflare-wallet/sdk';
import ADXCoin from '../service/ADXCoin';
import Web3 from 'web3';
import QRCode from 'qrcode.react';
import Order from '../service/Order';

import "../assets/css/Buy.css"


class Buy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            coin: "USDT",
            price: {
                SOL: null,
                BNB: null,
                USDT: null,
            },
            formData: {
                qty: 1,
                amount: 1,
                coin: "USDT",
                orderid: null
            },
            qty: 1,
            amount: ADXCoin.ADXPriceInUSD,
            transactionid: null,
            isSubmit: false,
            isConnecting: false,
            walletname: "solflare",
            isShowConnect: false,
            isShowQr: false,
            error: null,
            success: null,
            showAddressTooltip: false,
            showAmountTooltip: false,
        };
        this.targetAddress=createRef();
    }
    componentDidMount() {
        ADXCoin.ADXPriceUpdate = this.handlePriceChange;
        this.intervalId = setInterval(this.handleInterval, 1000);
        this.metamaskwallet = new MetaMaskSDK();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
    handleInterval = () => {
        
        if (this.state.isShowQr && this.state.formData.orderid) {
            this.handleUpdateOrder()
        }
    }
    handleUpdateOrder = async () => {
        let orderid = this.state.formData.orderid;
        console.log("orderid",orderid)
        if (this.state.coin === "SOL") {
            const tx = await this.getLastTransactionSOL(ADXCoin.Account.SOL.address);
            if (tx) {
                await Order.updateOrder({
                    signature: tx.transactionId,
                    user_wallet: tx.from,
                    amount: tx.amount.toString(),
                    orderstatus: "paid",
                }, this.state.formData.orderid)
                let isSubmit = false;
                let isShowQr = false;
                let isConnecting = false;
                orderid=null;
                console.log("tx",tx)
                this.setState({ isShowQr, isSubmit, isConnecting, orderid })
            }
        } else {
            if (this.state.coin === "USDT") {
                const tx = await this.getLastTransactionUSDT(/*ADXCoin.Account.USDT.address*/TETHER_WALLET);
                if (tx) {
                    await Order.updateOrder({
                        signature: tx.transactionId,
                        user_wallet: tx.from,
                        amount: tx.amount.toString(),
                        orderstatus: "paid",
                    }, this.state.formData.orderid)
                    let isSubmit = false;
                    let isShowQr = false;
                    let isConnecting = false;
                    orderid=null;
                    console.log("tx",tx)
                    this.setState({ isShowQr, isSubmit, isConnecting, orderid })
                }
            } else {
                const tx = await this.getLastTransactionBNB(ADXCoin.Account.BNB.address);
                if (tx) {
                    await Order.updateOrder({
                        signature: tx.transactionId,
                        user_wallet: tx.from,
                        amount: tx.amount.toString(),
                        orderstatus: "paid",
                    }, this.state.formData.orderid)
                    let isSubmit = false;
                    let isShowQr = false;
                    let isConnecting = false;
                    orderid=null;
                    console.log("tx",tx)
                    this.setState({ isShowQr, isSubmit, isConnecting, orderid })
                }
            }
        }

    }
    async getLastTransactionUSDT(address) {
        const web3 = new Web3(BINANCE_W3);
        const latestBlock = await web3.eth.getBlockNumber();
        for (let i = Number(latestBlock); i >= Number(latestBlock) - (100); i--) {
            const block = await web3.eth.getBlock(i, true);
            for (let tx of block.transactions) {
                if (tx.to && tx.to.toLowerCase() === TETHER_WALLET_CONTRACT.toLowerCase()) {
                    const receipt = await web3.eth.getTransactionReceipt(tx.hash);
                    const logs = receipt.logs.filter(log => log.address.toLowerCase() === TETHER_WALLET_CONTRACT.toLowerCase());

                    for (let log of logs) {
                        const from = '0x' + log.topics[1].slice(26);
                        const to = '0x' + log.topics[2].slice(26);

                        if (from.toLowerCase() === address.toLowerCase() || to.toLowerCase() === address.toLowerCase()) {
                            return { to: address, from: from, transactionId: tx.hash, amount: web3.utils.fromWei(web3.utils.hexToNumberString(log.data), 'ether') };
                        }
                    }
                }
            }
        }
        return null;
    }
    async getLastTransactionBNB(address) {
        const web3 = new Web3(BINANCE_W3);
        const latestBlock = await web3.eth.getBlockNumber();
        for (let i = latestBlock; i >= latestBlock - 100; i--) {
            const block = await web3.eth.getBlock(i, true);
            for (let tx of block.transactions) {
                if (tx.from.toLowerCase() === address.toLowerCase() || (tx.to && tx.to.toLowerCase() === address.toLowerCase())) {
                    return { to: tx.to, from: tx.from, transactionId: tx.hash, amount: web3.utils.fromWei(tx.value, 'ether') };
                }
            }
        }
        return null;
    }

    async getLastTransactionSOL(address) {
        const connection = new Connection(SOLANA_ENDPOINT);
        const publicKey = new PublicKey(address);
        const confirmedSignatures = await connection.getSignaturesForAddress(publicKey, { limit: 1 });
        const now = Date.now() / 1000;
        const fiveMinutesAgo = now - 5 * 60;

        if (confirmedSignatures.length > 0) {
            const signature = confirmedSignatures[0].signature;
            const transaction = await connection.getParsedTransaction(signature, 'confirmed');
            if (transaction) {
                const blockTime = transaction.blockTime;
                if (blockTime && blockTime >= fiveMinutesAgo) {
                    return { to: transaction.transaction.message.accountKeys[1].toBase58(), from: transaction.transaction.message.accountKeys[0].toBase58(), transactionId: signature, amount: (transaction.meta.postBalances[1] - transaction.meta.preBalances[1]) / LAMPORTS_PER_SOL };
                }
            }
        }
        return null;
    }
    handleAddresCopy = (value) => {
        window.navigator.clipboard.writeText(value).then(() => {
            this.setState({ showAddressTooltip: true });
            setTimeout(() => this.setState({ showAddressTooltip: false }), 2000);
        }).catch((err) => {
            console.error('Failed to copy: ', err);
        });
    }
    handlePriceChange = async () => {
        const price = ADXCoin.ADXPrices;
        this.setState({ price })
    }
    handleChange = (e) => {
        const { name } = e.target;
        const qty = this.state.qty;
        let amount = this.state.amount;
        if (qty > 0) {
            amount = qty * (1 / this.state.price[name]) * ADXCoin.ADXPriceInUSD
        }
        this.setState({ coin: name, amount })
    }
    handleQtyChange = (e) => {
        const { value } = e.target;
        const qty = value;
        let amount = this.state.amount;
        if (qty > 0) {
            amount = qty * (1 / this.state.price[this.state.coin]) * ADXCoin.ADXPriceInUSD
        }
        this.setState({ qty, amount })

    }

    handleAmountChange = (e) => {
        const { value } = e.target;
        let qty = this.state.qty;
        const amount = value;
        if (amount > 0) {
            qty = amount / ((1 / this.state.price[this.state.coin]) * ADXCoin.ADXPriceInUSD)
        }
        this.setState({ qty, amount })

    }

    handleTransationChange = async (e) => {
        const { value } = e.target;

        this.setState({ transactionid: value })
    }
    handleQrSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.transactionid) {
            await Order.updateOrder({
                signature: this.state.transactionid
            }, this.state.formData.orderid)
            let isSubmit = false;
            let isShowQr = false;
            let isConnecting = false;
            this.setState({ isShowQr, isSubmit, isConnecting })
        } else {
            let error = "transation id is required";
            this.setState({ error })
        }
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const qty = this.state.qty;
        const amount = this.state.amount;
        let isSubmit = true;
        this.setState({  isSubmit })
        if (qty > 0 && amount > 0) {
            const coin = this.state.coin;
            const orderid = await Order.generateOrderID();
            const formData = {
                qty: qty,
                amount: amount,
                coin: coin,
                orderid: orderid
            }

            const isShowConnect = true;
            this.setState({ isShowConnect, isSubmit, formData })

        }else{
            isSubmit = false;
            this.setState({  isSubmit })
        }
    }
    handleClose = () => {
        const isSubmit = this.state.isConnecting;
        const isShowConnect = this.state.isConnecting;
        this.setState({ isSubmit, isShowConnect })
    }

    handleSolflareConnect = async (e) => {
        e.preventDefault()
        let isConnecting = true;
        let isSubmit = true;
        let isShowConnect = true;
        let error, success = null;
        const walletname = "solflare";
        this.setState({ isConnecting, walletname, error, success })
        let isShowQr = false;
        try {
            const wallet = new Solflare();
            wallet.on('connect', () => {
                ADXCoin.Wallet = {
                    ...ADXCoin.Wallet,
                    solflare: wallet.publicKey.toString()
                }
            });
            await wallet.connect();
            if (wallet.isConnected) {
                const connection = new Connection(SOLANA_ENDPOINT);
                const reciveto = new PublicKey(ADXCoin.Account.SOL.address)
                const sendfrom = new PublicKey(ADXCoin.Wallet.solflare)

                const transaction = new Transaction().add(
                    SystemProgram.transfer({
                        fromPubkey: sendfrom,
                        toPubkey: reciveto,
                        lamports: parseInt((this.state.amount * LAMPORTS_PER_SOL).toString()),
                    })
                );
                const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();


                transaction.recentBlockhash = blockhash;
                transaction.lastValidBlockHeight = lastValidBlockHeight;

                const signedTransaction = await wallet.signTransaction(transaction);
                const signature = await connection.sendRawTransaction(signedTransaction.serialize());
                await connection.confirmTransaction({
                    signature,
                    blockhash,
                    lastValidBlockHeight,
                });
                console.log(signature)

                await Order.createOrder({
                    signature: signature,
                    user_wallet: ADXCoin.Wallet.solflare,
                    amount: this.state.amount.toString(),
                    payment_method: "solflare",
                    coinname: this.state.coin,
                    orderid: this.state.formData.orderid,
                    orderat: new Date().toDateString(),
                    orderstatus: "paid",
                    adrox_wallet: JSON.stringify(ADXCoin.Account.SOL),
                })
                success = "Payment Processed"
            } else {
                isShowQr = true;
                error = "Sollflare Wallet Not Connected"

                await Order.createOrder({
                    signature: '-',
                    user_wallet: '-',
                    amount: this.state.amount.toString(),
                    payment_method: "desktop",
                    coinname: this.state.coin,
                    orderid: this.state.formData.orderid,
                    orderat: new Date().toDateString(),
                    orderstatus: "pending",
                    adrox_wallet: JSON.stringify(ADXCoin.Account.SOL),
                })
            }
        } catch (er) {
            isShowQr = true;
            error = "Sollflare Wallet Not Connected";

            await Order.createOrder({
                signature: '-',
                user_wallet: '-',
                amount: this.state.amount.toString(),
                payment_method: "desktop",
                coinname: this.state.coin,
                orderid: this.state.formData.orderid,
                orderat: new Date().toDateString(),
                orderstatus: "pending",
                adrox_wallet: JSON.stringify(ADXCoin.Account.SOL),
            })
        }
        isConnecting = false;
        isSubmit = false;
        isShowConnect = false;
        this.setState({ isConnecting, walletname, isSubmit, isShowConnect, isShowQr, error, success })
    }

    handlePhantomConnect = async (e) => {
        e.preventDefault()
        let isConnecting = true;
        let isSubmit = true;
        let isShowConnect = true;
        let error, success = null;
        const walletname = "phantom";
        this.setState({ isConnecting, walletname, error, success })
        let isShowQr = false;
        try {
            if ('phantom' in window) {
                const provider = window.phantom?.solana;
                if (provider?.isPhantom) {
                    const wallet = await provider.connect();
                    ADXCoin.Wallet = {
                        ...ADXCoin.Wallet,
                        phantom: wallet.publicKey.toString()
                    }
                    const connection = new Connection(SOLANA_ENDPOINT);
                    const reciveto = new PublicKey(ADXCoin.Account.SOL.address)
                    const sendfrom = new PublicKey(ADXCoin.Wallet.phantom)

                    const transaction = new Transaction().add(
                        SystemProgram.transfer({
                            fromPubkey: sendfrom,
                            toPubkey: reciveto,
                            lamports: parseInt((this.state.amount * LAMPORTS_PER_SOL).toString()),
                        })
                    );
                    const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();


                    transaction.recentBlockhash = blockhash;
                    transaction.lastValidBlockHeight = lastValidBlockHeight;

                    const signedTransaction = await wallet.signTransaction(transaction);
                    const signature = await connection.sendRawTransaction(signedTransaction.serialize());
                    await connection.confirmTransaction({
                        signature,
                        blockhash,
                        lastValidBlockHeight,
                    });
                    await Order.createOrder({
                        signature: signature,
                        user_wallet: ADXCoin.Wallet.phantom,
                        amount: this.state.amount.toString(),
                        payment_method: "phantom",
                        coinname: this.state.coin,
                        orderid: this.state.formData.orderid,
                        orderat: new Date().toDateString(),
                        orderstatus: "paid",
                        adrox_wallet: JSON.stringify(ADXCoin.Account.SOL),
                    })
                    success = "Payment Processed"
                } else {

                    isShowQr = true;
                    error = "Phantom Wallet Not Connected"

                    await Order.createOrder({
                        signature: '-',
                        user_wallet: '-',
                        amount: this.state.amount.toString(),
                        payment_method: "desktop",
                        coinname: this.state.coin,
                        orderid: this.state.formData.orderid,
                        orderat: new Date().toDateString(),
                        orderstatus: "pending",
                        adrox_wallet: JSON.stringify(ADXCoin.Account.SOL),
                    })
                }
            } else {

                isShowQr = true;
                error = "Phantom Wallet Not Connected"

                await Order.createOrder({
                    signature: '-',
                    user_wallet: '-',
                    amount: this.state.amount.toString(),
                    payment_method: "desktop",
                    coinname: this.state.coin,
                    orderid: this.state.formData.orderid,
                    orderat: new Date().toDateString(),
                    orderstatus: "pending",
                    adrox_wallet: JSON.stringify(ADXCoin.Account.SOL),
                })
            }

        } catch (er) {
            isShowQr = true;
            error = "Phantom Wallet Not Connected";

            await Order.createOrder({
                signature: '-',
                user_wallet: '-',
                amount: this.state.amount.toString(),
                payment_method: "desktop",
                coinname: this.state.coin,
                orderid: this.state.formData.orderid,
                orderat: new Date().toDateString(),
                orderstatus: "pending",
                adrox_wallet: JSON.stringify(ADXCoin.Account.SOL),
            })
        }
        isConnecting = false;
        isSubmit = false;
        isShowConnect = false;
        this.setState({ isConnecting, walletname, isSubmit, isShowConnect, isShowQr, error, success })
    }
    handleMetamaskConnect = async (e) => {
        e.preventDefault()
        let isConnecting = true;
        let isSubmit = true;
        let isShowConnect = true;
        let error, success = null;
        const walletname = "metamask";
        this.setState({ isConnecting, walletname, error, success })
        let isShowQr = false;
        try {
            if (!ADXCoin.Wallet.metamask) {
                const accounts = await this.metamaskwallet.connect();
                if (accounts && accounts.length > 0) {
                    ADXCoin.Wallet = {
                        ...ADXCoin.Wallet,
                        metamask: accounts[0]
                    }
                }

            }
            if (ADXCoin.Wallet.metamask) {
                const web3 = new Web3(BINANCE_W3);
                const weiamount = web3.utils.toWei(this.state.amount, 'ether');

                if (this.state.coin === "USDT") {
                    const nonce = await web3.eth.getTransactionCount(ADXCoin.Wallet.metamask, 'latest');
                    const tokenABI = [
                        {
                            "constant": false,
                            "inputs": [
                                { "name": "_to", "type": "address" },
                                { "name": "_value", "type": "uint256" }
                            ],
                            "name": "transfer",
                            "outputs": [{ "name": "", "type": "bool" }],
                            "type": "function"
                        }
                    ];
                    const contract = new web3.eth.Contract(tokenABI, TETHER_WALLET_CONTRACT);
                    const data = contract.methods.transfer(/*ADXCoin.Account.USDT.address*/TETHER_WALLET, weiamount).encodeABI();
                    const transactionParameters = {
                        to: TETHER_WALLET_CONTRACT,
                        from: ADXCoin.Wallet.metamask,
                        value: weiamount,
                        gas: '0x5208',
                        data: data,
                        nonce: nonce
                    };

                    const txHash = await this.metamaskwallet.getProvider().request({
                        method: 'eth_sendTransaction',
                        params: [transactionParameters],
                    });

                    await Order.createOrder({
                        signature: txHash,
                        user_wallet: ADXCoin.Wallet.metamask,
                        amount: this.state.amount.toString(),
                        payment_method: "metamask",
                        coinname: this.state.coin,
                        orderid: this.state.formData.orderid,
                        orderat: new Date().toDateString(),
                        orderstatus: "paid",
                        adrox_wallet: JSON.stringify(ADXCoin.Account.USDT),
                    })
                    success = "Payment Processed"
                } else {
                    const transactionParameters = {
                        to: ADXCoin.Account.BNB.address,
                        from: ADXCoin.Wallet.metamask,
                        value: weiamount,
                        gas: '0x5208', // 21000 Gwei, standard gas limit for ETH transfers
                    };

                    const txHash = await (this.metamaskwallet.getProvider()).request({
                        method: 'eth_sendTransaction',
                        params: [transactionParameters],
                    });

                    await Order.createOrder({
                        signature: txHash,
                        user_wallet: ADXCoin.Wallet.metamask,
                        amount: this.state.amount.toString(),
                        payment_method: "metamask",
                        coinname: this.state.coin,
                        orderid: this.state.formData.orderid,
                        orderat: new Date().toDateString(),
                        orderstatus: "paid",
                        adrox_wallet: JSON.stringify(ADXCoin.Account.BNB),
                    })
                    success = "Payment Processed"
                }

            } else {

                isShowQr = true;
                error = "Metamask Wallet Not Connected"

                await Order.createOrder({
                    signature: '-',
                    user_wallet: '-',
                    amount: this.state.amount.toString(),
                    payment_method: "desktop",
                    coinname: this.state.coin,
                    orderid: this.state.formData.orderid,
                    orderat: new Date().toDateString(),
                    orderstatus: "pending",
                    adrox_wallet: this.state.coin === "USDT" ? JSON.stringify(ADXCoin.Account.USDT) : JSON.stringify(ADXCoin.Account.BNB),
                })
            }

        } catch (er) {
            console.log(er)
            isShowQr = true;
            error = "Metamask Wallet Not Connected";

            await Order.createOrder({
                signature: '-',
                user_wallet: '-',
                amount: this.state.amount.toString(),
                payment_method: "desktop",
                coinname: this.state.coin,
                orderid: this.state.formData.orderid,
                orderat: new Date().toDateString(),
                orderstatus: "pending",
                adrox_wallet: this.state.coin === "USDT" ? JSON.stringify(ADXCoin.Account.USDT) : JSON.stringify(ADXCoin.Account.BNB),
            })
        }
        isConnecting = false;
        isSubmit = false;
        isShowConnect = false;
        this.setState({ isConnecting, walletname, isSubmit, isShowConnect, isShowQr, error, success })
    }
    handleCoinBaseConnect = async (e) => {
        e.preventDefault()
        let isConnecting = true;
        let isSubmit = true;
        let isShowConnect = true;
        let error, success = null;
        const walletname = "coinbase";
        this.setState({ isConnecting, walletname, error, success })
        let isShowQr = false;
        try {
            const coinsdk = new CoinbaseWalletSDK({
                appName: 'Adrox',
                appChainIds: [56],
                appLogoUrl: "https://adroxmarket.com/favicon.ico"
            });
            const provider = coinsdk.makeWeb3Provider({ options: 'smartWalletOnly' });

            if (!ADXCoin.Wallet.coinbase) {
                const accounts = provider.request({ method: 'eth_requestAccounts' });
                if (accounts && accounts.length > 0) {
                    ADXCoin.Wallet = {
                        ...ADXCoin.Wallet,
                        coinbase: accounts[0]
                    }
                }

            }
            if (ADXCoin.Wallet.coinbase) {
                const web3 = new Web3(BINANCE_W3);
                const weiamount = web3.utils.toWei(this.state.amount, 'ether');

                if (this.state.coin === "USDT") {
                    const nonce = await web3.eth.getTransactionCount(ADXCoin.Wallet.coinbase, 'latest');
                    const tokenABI = [
                        {
                            "constant": false,
                            "inputs": [
                                { "name": "_to", "type": "address" },
                                { "name": "_value", "type": "uint256" }
                            ],
                            "name": "transfer",
                            "outputs": [{ "name": "", "type": "bool" }],
                            "type": "function"
                        }
                    ];
                    const contract = new web3.eth.Contract(tokenABI, TETHER_WALLET_CONTRACT);
                    const data = contract.methods.transfer(/*ADXCoin.Account.USDT.address*/TETHER_WALLET, weiamount).encodeABI();
                    const transactionParameters = {
                        to: TETHER_WALLET_CONTRACT,
                        from: ADXCoin.Wallet.coinbase,
                        value: weiamount,
                        gas: '0x5208',
                        data: data,
                        nonce: nonce
                    };

                    const txHash = await provider.request({
                        method: 'eth_sendTransaction',
                        params: [transactionParameters],
                    });

                    await Order.createOrder({
                        signature: txHash,
                        user_wallet: ADXCoin.Wallet.coinbase,
                        amount: this.state.amount.toString(),
                        payment_method: "coinbase",
                        coinname: this.state.coin,
                        orderid: this.state.formData.orderid,
                        orderat: new Date().toDateString(),
                        orderstatus: "paid",
                        adrox_wallet: JSON.stringify(ADXCoin.Account.USDT),
                    })
                    success = "Payment Processed"
                } else {
                    const transactionParameters = {
                        to: ADXCoin.Account.BNB.address,
                        from: ADXCoin.Wallet.coinbase,
                        value: weiamount,
                        gas: '0x5208', // 21000 Gwei, standard gas limit for ETH transfers
                    };

                    const txHash = await provider.request({
                        method: 'eth_sendTransaction',
                        params: [transactionParameters],
                    });

                    await Order.createOrder({
                        signature: txHash,
                        user_wallet: ADXCoin.Wallet.coinbase,
                        amount: this.state.amount.toString(),
                        payment_method: "coinbase",
                        coinname: this.state.coin,
                        orderid: this.state.formData.orderid,
                        orderat: new Date().toDateString(),
                        orderstatus: "paid",
                        adrox_wallet: JSON.stringify(ADXCoin.Account.BNB),
                    })
                    success = "Payment Processed"
                }

            } else {

                isShowQr = true;
                error = "Coinbase Wallet Not Connected"

                await Order.createOrder({
                    signature: '-',
                    user_wallet: '-',
                    amount: this.state.amount.toString(),
                    payment_method: "desktop",
                    coinname: this.state.coin,
                    orderid: this.state.formData.orderid,
                    orderat: new Date().toDateString(),
                    orderstatus: "pending",
                    adrox_wallet: this.state.coin === "USDT" ? JSON.stringify(ADXCoin.Account.USDT) : JSON.stringify(ADXCoin.Account.BNB),
                })
            }

        } catch (er) {
            console.log(er)
            isShowQr = true;
            error = "Coinbase Wallet Not Connected";

            await Order.createOrder({
                signature: '-',
                user_wallet: '-',
                amount: this.state.amount.toString(),
                payment_method: "desktop",
                coinname: this.state.coin,
                orderid: this.state.formData.orderid,
                orderat: new Date().toDateString(),
                orderstatus: "pending",
                adrox_wallet: this.state.coin === "USDT" ? JSON.stringify(ADXCoin.Account.USDT) : JSON.stringify(ADXCoin.Account.BNB),
            })
        }
        isConnecting = false;
        isSubmit = false;
        isShowConnect = false;
        this.setState({ isConnecting, walletname, isSubmit, isShowConnect, isShowQr, error, success })
    }
    handleShowQR = async () => {
        const isShowQr = true;
        const isConnecting = false;
        const isSubmit = false;
        const isShowConnect = false;
        this.setState({ isConnecting, isSubmit, isShowConnect, isShowQr })
        let ac = {};
        switch (this.state.coin) {
            case "SOL":
                ac = JSON.stringify(ADXCoin.Account.SOL)
                break;
            case "USDT":
                ac = JSON.stringify(ADXCoin.Account.USDT);
                break;
            case "BNB":
                ac = JSON.stringify(ADXCoin.Account.BNB);
                break;

            default:
                break;
        }
        await Order.createOrder({
            signature: "-",
            user_wallet: "-",
            amount: this.state.amount.toString(),
            payment_method: "desktop",
            coinname: this.state.coin,
            orderid: this.state.formData.orderid,
            orderat: new Date().toDateString(),
            orderstatus: "pending",
            adrox_wallet: ac,
        })
    }

    render() {
        return (
            <div className="sign-in-section pt-100 pb-100">
                <Container>
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={6} sm={12}>
                            <div className="sign-in-respond text-center">
                                <div className="sign-in-title">
                                    {window.trustwallet && (<>dd</>)}
                                    <h2>Presale is Live Now</h2>
                                    <p>1 ADX = 0.05 $</p>
                                </div>
                                {!this.state.isShowQr && (<div className="sign-button">
                                    <Link className={this.state.coin === "USDT" ? '' : "sign-button2"} onClick={this.handleChange} name={"USDT"} >USDT</Link>
                                    <Link className={this.state.coin === "BNB" ? '' : "sign-button2"} onClick={this.handleChange} name={"BNB"} >BNB</Link>
                                    <Link className={this.state.coin === "SOL" ? '' : "sign-button2"} onClick={this.handleChange} name={"SOL"} >SOL</Link>
                                </div>)}
                                <div className="contact-form-box style-four">
                                    {
                                        this.state.error && (<>
                                            <Alert variant={'danger'}>
                                                {this.state.error}
                                            </Alert>
                                        </>)
                                    }
                                    {
                                        this.state.success && (<>
                                            <Alert variant={'success'}>
                                                {this.state.success}
                                            </Alert>
                                        </>)
                                    }
                                    {this.state.isShowQr ? (<>
                                        <Form noValidate onSubmit={this.handleQrSubmit}>
                                            <Row>
                                                <Col lg={12}>
                                                    <p className='text-white'>Open Your {this.state.coin} {this.state.coin==="USDT"&&(<>( BEP-20 )</>)} Wallet and Scan QR Code to pay {this.state.amount} {this.state.coin}</p>
                                                    {this.state.coin === "SOL" && (<>
                                                        <QRCode className='qr-image' value={ADXCoin.Account.SOL.address} size={256} />
                                                        <div className='from-box pb-85'>
                                                            <input type="text" value={ADXCoin.Account.SOL.address} readOnly />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                show={this.state.showAddressTooltip}
                                                                overlay={(props) => (
                                                                    <Tooltip  {...props}>
                                                                      Copied to clipboard!
                                                                    </Tooltip>
                                                                  )}
                                                                >
                                                                <i
                                                                    className="qr-copy"
                                                                    ref={this.targetAddress}
                                                                    style={{ cursor: 'pointer'}}
                                                                    onClick={() => this.handleAddresCopy(ADXCoin.Account.SOL.address)}
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy} /> Copy
                                                                </i>
                                                                </OverlayTrigger>
                                                        </div>
                                                    </>)}
                                                    {this.state.coin === "BNB" && (<>
                                                        <QRCode className='qr-image' value={ADXCoin.Account.BNB.address} size={256} />
                                                        <div className='from-box pb-85'>
                                                            <input type="text" value={ADXCoin.Account.BNB.address} readOnly />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                show={this.state.showAddressTooltip}
                                                                overlay={(props) => (
                                                                    <Tooltip  {...props}>
                                                                      Copied to clipboard!
                                                                    </Tooltip>
                                                                  )}
                                                                >
                                                                <i
                                                                    className="qr-copy"
                                                                    ref={this.targetAddress}
                                                                    style={{ cursor: 'pointer'}}
                                                                    onClick={() => this.handleAddresCopy(ADXCoin.Account.BNB.address)}
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy} /> Copy
                                                                </i>
                                                                </OverlayTrigger>
                                                        </div>
                                                    </>)}
                                                    {this.state.coin === "USDT" && (<>
                                                        <QRCode className='qr-image' value={/*ADXCoin.Account.USDT.address*/TETHER_WALLET} size={256} />
                                                        <div className='from-box pb-85'>
                                                            <input type="text" value={/*ADXCoin.Account.USDT.address*/TETHER_WALLET} readOnly />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                show={this.state.showAddressTooltip}
                                                                overlay={(props) => (
                                                                    <Tooltip  {...props}>
                                                                      Copied to clipboard!
                                                                    </Tooltip>
                                                                  )}
                                                                >
                                                                <i
                                                                    className="qr-copy"
                                                                    ref={this.targetAddress}
                                                                    style={{ cursor: 'pointer'}}
                                                                    onClick={() => this.handleAddresCopy(/*ADXCoin.Account.USDT.address*/TETHER_WALLET)}
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy} /> Copy
                                                                </i>
                                                                </OverlayTrigger>
                                                        </div>
                                                    </>)}
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="from-box pb-85">
                                                        <input type="text" value={this.state.amount} readOnly={true} />
                                                    </div>
                                                </Col>


                                                <Col lg={12}>
                                                    <div className="from-box pb-85">
                                                        <input type="text" placeholder="Enter transantion id" value={this.state.transactionid} name='transactionid' onChange={this.handleTransationChange} />
                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="contact-items">
                                                        <Button type='submit' disabled={this.state.isSubmit}>Submit {this.state.isSubmit && (<><Spinner animation="grow" /></>)}</Button>
                                                        <br/><br/>  <p>
                                                            Payment status will update in up to 24 hours, and you will receive confirmation in your dashboard.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </>) : (<Form noValidate onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="from-box pb-85">
                                                    <input type="text" placeholder="Number of ADROX to buy" name='qty' value={this.state.qty} onChange={this.handleQtyChange} />
                                                    <i ><FontAwesomeIcon icon={faCoins} /> ADX</i>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="from-box">
                                                    <input type="text" placeholder="0" value={this.state.amount} name='amount' onChange={this.handleAmountChange} />
                                                    <i ><FontAwesomeIcon icon={faCoins} /> {this.state.coin}</i>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="contact-items">
                                                    <Button type='submit' disabled={this.state.isSubmit}>Buy {this.state.isSubmit && (<><Spinner animation="grow" /></>)}</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>)}
                                    <div id="status"></div>
                                    <div><br /><br />
                                        <strong>Important Note Before Proceeding with Payment:</strong>
                                        <br /><br />
                                        <p> If you encounter any difficulties or issues during the payment process, please do not hesitate to reach out to our support team for assistance. You can contact us via email at <a href="mailto:help@adroxmarket.com">Help@adroxmarket.com</a>. We’re here to help!</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3}></Col>
                    </Row>
                </Container>

                <Modal show={this.state.isShowConnect} onHide={this.handleClose} backdrop="static" className='connecting-bg' size='lg' keyboard={false} centered >
                    <Modal.Header closeButton>
                        <Modal.Title className='text-white'>Select Your Wallet  </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.state.coin === "SOL" && (<Col lg={4} md={6} sm={12}>
                                <div className="d-grid gap-2 m-2">
                                    <Button variant="dark" className='connecting-btn' size="lg" disabled={this.state.isConnecting} onClick={this.handleSolflareConnect} >
                                        <img src={solflareImg} alt='solflare wallet' />  {this.state.isConnecting && this.state.walletname === "solflare" && (<><Spinner animation="grow" /></>)}
                                    </Button>
                                </div>
                            </Col>)}

                            {this.state.coin === "SOL" && (<Col lg={4} md={6} sm={12}>
                                <div className="d-grid gap-2 m-2">
                                    <Button variant="dark" className='connecting-btn' size="lg" disabled={this.state.isConnecting} onClick={this.handlePhantomConnect} >
                                        <img src={phantomImg} alt='Phantom wallet' />  {this.state.isConnecting && this.state.walletname === "phantom" && (<><Spinner animation="grow" /></>)}
                                    </Button>
                                </div>
                            </Col>)}

                            {this.state.coin !== "SOL" && (<Col lg={4} md={6} sm={12}>
                                <div className="d-grid gap-2 m-2">
                                    <Button variant="light" className='connecting-btn' size="lg" disabled={this.state.isConnecting} onClick={this.handleMetamaskConnect} >
                                        <img src={metamaskImg} alt='Meta MAsk wallet' />  {this.state.isConnecting && this.state.walletname === "metamask" && (<><Spinner animation="grow" /></>)}
                                    </Button>
                                </div>
                            </Col>)}

                            {this.state.coin !== "SOL" && (<Col lg={4} md={6} sm={12}>
                                <div className="d-grid gap-2 m-2">
                                    <Button variant="light" className='connecting-btn' size="lg" onClick={this.handleCoinBaseConnect} >
                                        <img src={coinbaseImg} alt='Coinbase wallet' />   {this.state.isConnecting && this.state.walletname === "coinbase" && (<><Spinner animation="grow" /></>)}
                                    </Button>
                                </div>
                            </Col>)}

                            <Col lg={4} md={6} sm={12}>
                                <div className="d-grid gap-2 m-2">
                                    <Button variant="light" className='connecting-btn' size="lg" onClick={this.handleShowQR} >
                                        Scan or Copy Address
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>

                </Modal>
            </div>);
    }
}
export default Buy;